<template>
  <div>
    <VContent class="view-content">
      <VContainer fluid pa-0>
        <slot />
      </VContainer>
    </VContent>
    <AuthModal />
  </div>
</template>

<script>
import AuthModal from '@modals/AuthModal'

export default {
  components: { AuthModal },
}
</script>
