<template>
  <div
    ref="layoutGrid"
    class="grid"
    :class="{ 'grid--editing': isShowingLayoutEditUI }"
    :style="layoutStyles"
  >
    <VSheet v-if="!layout.regions.length" class="pa-5"
      ><h1 class="text-xs-center">
        There aren't any Layout Regions to display.
      </h1></VSheet
    >
    <template v-for="region in layout.regions" v-else>
      <WithLayoutRegion
        v-slot="{ isRegionLoading, theRegion }"
        :key="region.id"
        :region-id="region.id"
      >
        <BaseLoader v-if="isRegionLoading" />
        <BaseLayoutRegion
          v-else
          :region="theRegion"
          :font-size="fontSize"
          :elements="theRegion.elements"
          :ratio="layoutRatio"
          @doneLoading="loaded++"
        />
      </WithLayoutRegion>
    </template>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

import WithLayoutRegion from '@dataProviders/WithLayoutRegion'

export default {
  name: 'LayoutGrid',
  components: {
    WithLayoutRegion,
  },
  props: {
    layout: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      previewWidth: 1,
      loaded: 0,
      refreshInterval: null,
    }
  },
  computed: {
    ...mapGetters('layoutEditor', [
      'isShowingLayoutEditUI',
      'isInMenuEditMode',
    ]),
    ...mapGetters('auth', ['loggedIn']),
    layoutRatio() {
      let isLayoutRoute = this.$route.name === 'layout'
      return isLayoutRoute ? this.previewWidth / this.layoutWidth : 1
    },
    fontSize() {
      return parseInt(this.layout.font_size, 10)
    },
    layoutWidth() {
      return this.layout.width ? this.layout.width : 1
    },
    layoutStyles() {
      let style = this.layout.meta_info ? this.layout.meta_info.style : ''

      if (this.isShowingLayoutEditUI) {
        style += 'overflow: hidden; border:solid 4px #888;'
        if (this.layout.width && this.layout.height) {
          style += `height:${this.layoutRatio * this.layout.height}px;`
        }
      }

      if (this.isInMenuEditMode) {
        style += 'background-color:white !important;'
      }

      return style
    },
    hasLoadedAllRegions() {
      return this.loaded >= this.layout.regions.length
    }, // hasLoadedAllRegions
    scrollOnLoad() {
      return `#${this.layout.slug}` === location.hash
    },
  },
  watch: {
    isShowingLayoutEditUI() {
      this.setPreviewWindowWidth()
    },
    hasLoadedAllRegions(hasLoadedAllRegions) {
      if (hasLoadedAllRegions) {
        this.$nextTick(() => {
          this.$emit('doneLoading')
        })
      }
    },
    loggedIn(loggedIn) {
      this.toggleRefreshInterval(loggedIn)
    },
  },
  created() {
    window.addEventListener('resize', this.windowResized)
  },
  mounted() {
    this.setPreviewWindowWidth()
    this.toggleRefreshInterval(this.loggedIn)
  },
  destroyed() {
    window.removeEventListener('resize', this.windowResized)
    this.clearRefreshInterval()
  },
  methods: {
    ...mapActions('layouts', ['checkForUpdates']),
    setPreviewWindowWidth() {
      if (!this.$refs.layoutGrid) {
        return
      }

      this.previewWidth =
        this.layoutW === 1 ? 1 : this.$refs.layoutGrid.offsetWidth
    },
    windowResized() {
      this.setPreviewWindowWidth()
    },
    toggleRefreshInterval(loggedIn) {
      if (!loggedIn && !this.refreshInterval) {
        // if user isn't logged in and the interval doesn't exist, init it
        this.initRefreshPolling()
      } else if (loggedIn && !!this.refreshInterval) {
        // if user is logged in and the interval exists, clear it
        this.clearRefreshInterval()
      } else if (!loggedIn && this.refreshInterval) {
        this.clearRefreshInterval()
        this.initRefreshPolling()
      }
    }, // toggleRefreshInterval
    initRefreshPolling() {
      this.refreshInterval = setInterval(
        () => this.checkForUpdates(this.layout),
        1000 * 20
      )
    }, // initRefreshPolling
    clearRefreshInterval() {
      if (this.refreshInterval) {
        clearInterval(this.refreshInterval)
      }
    }, // clearRefreshInterval
  },
}
</script>

<style lang="scss" scoped>
.grid {
  display: grid;
}
.grid--editing {
  margin-top: 3em;
}
</style>
