<template>
  <Layout content-only>
    <div
      v-shortkey="{ toggleLayout: ['esc'], toggleMenuEdit: ['shift', 'esc'] }"
      :class="`layout-container layout-container-${layout.slug}`"
      @shortkey="shortkeyHandler"
    >
      <VLayout align-items-stretch fill-height>
        <LayoutEditorSidebar
          v-if="isShowingLayoutEditUI"
          :layout="layout"
          :location="location"
        />
        <VFlex>
          <BaseThemeComponent
            :name="layoutWrapperElem"
            :class="[isInMenuEditMode ? 'menu-editing-on' : 'menu-editing-off']"
          >
            <LayoutGrid :layout="layout" :location="location" />
          </BaseThemeComponent>
        </VFlex>
      </VLayout>

      <PortalTarget
        v-if="isShowingLayoutEditUI"
        name="layout-editor-header"
        class="layout-editor-header"
      />
    </div>
    <EditorSnackbar />
    <BaseMenuComponent name="MenuSnackbar" />
  </Layout>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import Layout from '@utils/get-layout'

import LayoutGrid from '@components/Layout/LayoutGrid'
import LayoutEditorSidebar from '@components/Layout/LayoutEditorSidebar'
import EditorSnackbar from '@editor/EditorSnackbar'

export default {
  name: 'LayoutEditor',
  components: {
    LayoutGrid,
    LayoutEditorSidebar,
    Layout,
    EditorSnackbar,
  },
  props: {
    location: {
      type: Object,
      required: true,
    },
    layout: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {}
  },
  computed: {
    ...mapGetters('layoutEditor', [
      'isShowingLayoutEditUI',
      'isInMenuEditMode',
    ]),
    layoutWrapperElem() {
      if (!this.layout.type.length) return 'Default'
      let typeTitleCase = this.layout.type
        ? this.layout.type.charAt(0).toUpperCase() + this.layout.type.slice(1)
        : 'Default'
      return `Layouts/${typeTitleCase}Layout`
    }, // layoutWrapperElem
  },
  watch: {
    isShowingLayoutEditUI: {
      handler(val) {
        if (!val) {
          document
            .getElementsByTagName('html')[0]
            .classList.add('hiding-layout-ui')
        } else {
          document
            .getElementsByTagName('html')[0]
            .classList.remove('hiding-layout-ui')
        }
      },
      immediate: true,
    },
  },
  created() {
    window.addEventListener('resize', this.windowResized)
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.windowResized)
    document
      .getElementsByTagName('html')[0]
      .classList.remove('hiding-layout-ui')
  }, // watch
  methods: {
    ...mapActions('layoutEditor', ['toggleLayoutEditUI']),
    ...mapActions('editor', ['toggleEditUI']),
    shortkeyHandler(evt) {
      switch (evt.srcKey) {
        case 'toggleLayout':
          this.toggleLayoutEditUI()
          break
        case 'toggleMenuEdit':
          this.toggleEditUI()
          break
        default:
          break
      }
    }, // shortkeyHandler
  }, // methods
}
</script>

<style lang="scss">
html.hiding-layout-ui {
  ::-webkit-scrollbar {
    display: none;
  }
}
.layout-container {
  height: 100%;
}
.layout-editor-header {
  position: absolute;
  top: 0;
  left: 50%;
  z-index: 999;
  width: 170px;
  margin-left: -85px;
}
</style>
